main {
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 1.45rem;
}

.App {
  padding-top: 1.5rem;
}

.principalHeader {
  width: 100%;
  height: 15rem;
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #07456d,
    #0078a3,
    #00afd5,
    #00e9ff
  );
  box-shadow: inset 0px -5px 8px -5px rgba(0, 0, 0, 0.5);
  width: 100%;
}
.innerHeader {
  margin: 0 auto;
  max-width: 960px;
  padding: 1rem 1.0875rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logoHeader {
  margin: auto 0;
  height: 70px;
}
.logoAndTitle {
  display: flex;
  align-items: center;
}

.creditsCard {
  max-width: 300px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 2px 8px #ccc;
}
.historyCard {
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 8px #ccc;
}

.totalCreditsCard {
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 8px #ccc;
  color: #fff;
}
.totalCreditsCard h1 {
  color: #fff;
}

.expenseContainer {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.expenseCredits {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.expensesLoader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.iconFeedback {
  font-size: 50px;
  margin-top: 1rem;
}

.background1 {
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #07456d,
    #0078a3,
    #00afd5,
    #00e9ff
  );
}

.background2 {
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #005181,
    #0090a0,
    #00cd7c,
    #aaff00
  );
}

.background3 {
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #6e326b,
    #db4b6a,
    #ff973b,
    #ffff00
  );
}

.background4 {
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #3f3a63,
    #7a5f8e,
    #bb85b7,
    #ffaedc
  );
}

.background5 {
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #2d295b,
    #60367a,
    #9a3c8f,
    #d73f98
  );
}

.background6 {
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #41205f,
    #90006a,
    #d7004e,
    #ff0000
  );
}
