.loginContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #07456d,
    #0078a3,
    #00afd5,
    #00e9ff
  );
}

.loginCard {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

.loginInput {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.loginLogo {
  height: 70px;
}

.loginTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1.3rem;
}
